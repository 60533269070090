import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import styles from '../../styles/install-page.module.scss';
import Icon1 from "../../images/svgs/Icon1.svg";
import Icon2 from "../../images/svgs/Icon2.svg";
import Icon3 from "../../images/svgs/Icon3.svg";
import Logo from "../../images/svgs/logo.svg";

import BackgroundImage from 'gatsby-background-image';

const InstallPage = () => {
  const { mainBackground, textBubbleBackground } = useStaticQuery(graphql`
  query {
    mainBackground: file(relativePath: { eq: "install-bg.jpg" }) {
      childImageSharp {
        fluid(quality:100,webpQuality: 100 ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    textBubbleBackground: file(relativePath: { eq: "install-bubble-bg.png" }) {
      childImageSharp {
        fluid(quality:100 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return (
    <BackgroundImage fluid={mainBackground.childImageSharp.fluid} className={styles.mainBg}>

      < div className={styles.container} >
        <div className={styles.header}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.pinIt}><span className={styles.pinItTxt}>Pin it!</span> <span className={styles.pinItIcon}><Icon1 /></span></div>
        </div>

        <BackgroundImage fluid={textBubbleBackground.childImageSharp.fluid} className={styles.contentBg}>
          <div className={styles.content}>
            <div className={styles.title}>
              <div>Welcome to</div>
              <div>Instant News!</div>
              <div>Here's how to get started.</div>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}><span>STEP</span><span>01</span></div>
              <div className={styles.stepText}>Click the Extension Icon.</div>
              <div className={styles.stepIcon}><Icon1 /></div>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}><span>STEP</span><span>02</span></div>
              <div className={styles.stepText}>Pin Instant News to your browser.</div>
              <div className={styles.stepIcon}><Icon2 /></div>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}><span>STEP</span><span>03</span></div>
              <div className={styles.stepText}>Access the latest news. Instantly.</div>
              <div className={styles.stepIcon}><Icon3 /></div>
            </div>
          </div>
        </BackgroundImage>
      </div >
    </BackgroundImage>
  )
}

export default InstallPage